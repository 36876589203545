import React, { useState, useEffect } from "react";
import {
  addCartMutation,
  getProductsQuery,
  updateCarts,
  getProductImages,
  getProductByCategoryID,
} from "../../services/productService";
import {
  Box,
  Divider,
  Grid,
  Rating,
  SwipeableDrawer,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { FaStar } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import FormatPrice from "../../FormatPrice";
import { getLocalUserId } from "../../utils/getLocalUserId";
import { useGetCarts } from "../Hooks/useCartsService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCart } from "react-use-cart";
import axios from "axios";
import Review from "./Review.js";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";

const checkSameOrder = (id, data) => {
  if (!data[id]) {
    return 0;
  }

  return data[id].quantity;
};

function Product() {
  const navigate = useNavigate();
  const { data: cartData } = useGetCarts();
  const params = useParams();
  const queryClient = useQueryClient();
  const [imageIndex, setImageIndex] = useState(0);
  const [youMayAlsoLikeData, setYouMayAlsoLikeData] = useState([]);

  const { data } = useQuery({
    queryKey: ["product", params],
    queryFn: getProductsQuery,
  });

  const mutateCart = useMutation({
    mutationKey: ["add-cart"],
    mutationFn: addCartMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries("get-cart");
      toast.success("Item added to Cart");
    },
    onError: (e) => {
      toast.error("Something went wrong!");
    },
  });

  const mutateUpdateCart = useMutation({
    mutationKey: ["update-cart"],
    mutationFn: updateCarts,
    onSuccess: () => {
      queryClient.invalidateQueries("get-cart");
      // todo: handle case when user had clicked buy it now
      toast.info("Items details updated");
    },
  });

  const addOnCart = () => {
    let productExist = false;
    let dataTosent = {};
    for (const [key, value] of Object.entries(cartData?.data.data)) {
      if (value.product_id == data.data.product_id) {
        productExist = true;
        dataTosent.cart_id = value.id;
        dataTosent.quantity = value.quantity + 1;
        break;
      }
    }
    // console.log("cartdata", cartData?.data.data);

    if (productExist) {
      mutateUpdateCart.mutate(dataTosent);
    } else {
      const item = {
        data: data?.data,
        quantity: 1,
      };
      const user_id = getLocalUserId();
      mutateCart.mutate({
        ...item,
        user_id,
      });
    }
    return;
  };
  const addToCart = () => {
    addOnCart({});
  };
  const buyNow = () => {
    addOnCart({});
    navigate("/add-to-cart");
  };
  // const buyNow = () => {

  // };

  const { addItem } = useCart();

  const id = data?.data?.product_id;
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const handleReview = async () => {
      try {
        const user_id = getLocalUserId();
        const id = data?.data?.product_id;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/${id}/reviews`
        );

        const storedReview = response.data;
        setReviews(storedReview.data);
        console.log("Review fetched successfully", response.data);
      } catch (error) {
        console.error("Error in fetching reviews", error);
      }
    };

    handleReview();
  }, [data]);

  const [newReview, setNewReview] = useState({
    rating: 0,
    comment: "",
  });

  const handleRatingChange = (rating) => {
    setNewReview((prevReview) => ({ ...prevReview, rating }));
  };

  const handleCommentChange = (event) => {
    const comment = event.target.value;
    setNewReview((prevReview) => ({ ...prevReview, comment }));
  };

  const submitReview = async () => {
    try {
      if (newReview.rating < 1 || newReview.rating > 5) {
        toast.error("Rating should be between 1 and 5");
        return;
      }

      const user_id = getLocalUserId();
      const product_id = data?.data?.product_id || "";

      const formData = new FormData();
      formData.append("rating", newReview.rating.toString());
      formData.append("comment", newReview.comment);
      formData.append("user_id", user_id);
      formData.append("product_id", product_id);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reviews`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Review submitted successfully");
      // handleReview();
    } catch (error) {
      console.error("Error submitting review", error);
      toast.error("Error submitting review");
    }
  };

  const { data: imagesData } = useQuery({
    queryKey: ["product-images", { id: data?.data?.product_id }],
    queryFn: () =>
      data?.data?.product_id ? getProductImages(data?.data?.product_id) : null,
  });
  const mutateGetProductByCategory = useMutation({
    mutationFn: getProductByCategoryID,
    mutationKey: ["get_product_by_category_id"],
    onSuccess: (data) => {
      setYouMayAlsoLikeData(data?.data?.slice(0, 4));
    },
    onError: (e) => {
      toast("Something went wrong!");
    },
  });

  const images = imagesData?.data;
  console.log("images", images);
  const isMobile = window.innerWidth <= 767;

  const product = data?.data;

  const generateProductJsonLd = (product) => ({
    "@context": "http://schema.org",
    "@type": "Product",
    name: product?.product_name,
    description: product?.product_description.replace(/<[^>]*>?/gm, ""),
    sku: product?.sku,
    category: product?.category_name,
    image: product?.images?.map((image) => image.image_url),
    offers: [
      {
        "@type": "Offer",
        price: product?.product_sell_price,
        priceCurrency: "INR",
        availability: "http://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: "Kukunamkeen",
        },
      },
      {
        "@type": "Offer",
        price: product?.product_price,
        priceCurrency: "INR",
        availability: "http://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: "Kukunamkeen",
        },
        priceValidUntil: "2024-06-09",
        priceSpecification: {
          "@type": "PriceSpecification",
          discount: {
            "@type": "QuantitativeValue",
            value: (
              product?.product_price - product?.product_sell_price
            ).toFixed(2),
            priceCurrency: "INR",
          },
        },
      },
    ],
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4",
      reviewCount: "80",
    },
  });

  return (
    <>
      <Helmet>
        <title>{data?.data?.meta_title}</title>
        <meta name="description" content={data?.data?.meta_description} />
        <meta name="keywords" content={data?.data?.meta_keywords} />
        <link
          rel="canonical"
          href={`https://kukunamkeen.in/product/${product?.slug}`}
        />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-VY1YL2WN1S"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VY1YL2WN1S');
    `}
        </script>
        <script type="text/javascript">
          {`
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "mdcd40ib4r");
    `}
        </script>
        <script>
          {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '398356959855805');
      fbq('track', 'PageView');
    `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(generateProductJsonLd(product))}
        </script>
        <noscript>
          {`
      <img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=398356959855805&ev=PageView&noscript=1" />
    `}
        </noscript>
      </Helmet>
      <br />

      <div className="flex flex-col md:flex-row lg:flex-row w-full px-5 lg:px-10 md:px-10  lg:px-24 mt-24 ">
        {isMobile ? (
          <div className="w-full flex flex-col-reverse lg:flex-row md:flex-row">
            <div className="w-full flex justify-center">
              <div className="flex flex-row lg:flex-col md:flex-col justify-center align-middle">
                <Carousel showThumbs={false} selectedItem={imageIndex}>
                  {images?.map((image, index) => (
                    <div
                      onClick={() => setImageIndex(index)}
                      key={`image-mobile-${index}`}
                    >
                      <img
                        src={`https://api.kukunamkeen.in/uploads/uploads/${image.image_url}`}
                        alt={image?.alt_text ? image?.alt_text : "image"}
                        className={`h-auto w-auto m-1 ${
                          imageIndex === index ? " " : ""
                        }`}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full  flex flex-col-reverse lg:flex-row md:flex-row ">
            <div className="flex flex-row lg:flex-col md:flex-col justify-center align-middle">
              {images?.map((image, index) => (
                <div
                  className=""
                  onClick={() => setImageIndex(index)}
                  key={`image-desktop-${index}`}
                >
                  <img
                    src={`https://api.kukunamkeen.in/uploads/uploads/${image.image_url}`}
                    alt={image?.alt_text ? image?.alt_text : "image"}
                    className={`h-28 w-30 m-1 ${
                      imageIndex === index ? "ring-1 ring-black " : ""
                    }`}
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Zoom className="align-middle flex justify-center">
                <img
                  src={`https://api.kukunamkeen.in/uploads/uploads/${images?.[imageIndex]?.image_url}`}
                  className="w-full p-0 lg:p-5 md:p-5"
                  alt="bg-image"
                  style={{
                    maxHeight: "auto",
                    borderRadius: "5px",
                  }}
                />
              </Zoom>
            </div>
          </div>
        )}
        <div className="w-full flex justify-center mt-10 lg:mt-0 md:mt-0">
          <div className="flex flex-col w-full lg:w-2/3 md:w-2/3 sm:w-2/3  align-middle px-auto justify-center px-auto">
            <h1 className="text-3xl font-bold">{data?.data?.product_name}</h1>
            <p
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: data?.data?.product_description,
              }}
            ></p>

            <div className="mt-4">
              <div className="flex flex-col">
                <label className="inline-flex items-center">
                  <del className="">
                    <FormatPrice price={data?.data?.product_price} />
                  </del>
                  <span className="ml-2 md:ml-4" style={{ color: "#952F8A" }}>
                    <FormatPrice price={data?.data?.product_sell_price} />
                  </span>
                </label>
              </div>
            </div>

            <div className="mt-4 flex gap-5">
              <div className="text-white">
                <button
                  className="bg-[#952F8A] px-4 py-2 gap-2 rounded-3xl flex items-center"
                  onClick={addToCart}
                >
                  <img
                    src="/Image/Vector.svg"
                    className="text-white w-5 h-5"
                    alt="error"
                  />
                  <span>Add to Cart</span>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </button>
              </div>

              <div className="text-white">
                <button
                  className="border border-[#952F8A] text-[#952F8A] px-4 py-2 gap-2 rounded-3xl flex items-center"
                  onClick={buyNow}
                >
                  <span>Buy Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="flex flex-col lg:flex-row md:flex-row  w-full  pl-5 pr-5 lg:pl-24 lg:pr-24">
          <div className=" flex flex-col w-19/10">
            <h2 className="font-bold" style={{ fontSize: "32px" }}>
              Product Details
            </h2>
            <div
              className="flex gap-4 items-center mt-4"
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                color: "#505050",
              }}
            >
              {/*              <img src="/Image/Check.svg" alt="error" />*/}
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: data?.data?.product_description_two,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 ">
        <h2
          className="font-bold text-lg text-center"
          style={{ color: "Rockwell", fontSize: "32px" }}
        >
          Customers Feedback
        </h2>
        <div className="flex flex-col lg:flex-row md:flex-row sm:flex-row lg:mx-24 md:mx-10 mx-5 ">
          <div className=" bg-[#F9FAFB]   mt-10  space-y-5 rounded-2xl w-full   ">
            <div className="flex justify-center">
              <p className="flex pt-5 pl-5 font-semibold text-xl justify-center">
                Average Rating - 4
              </p>
            </div>
            <div className="flex ml-5 space-x-4 mt-2 pb-5 mr-5 ">
              <div className="w-full bg-gray-200  my-2  rounded-lg overflow-hidden border border-gray-300">
                <div
                  className="bg-[#20590C] text-xs leading-none py-1"
                  style={{ width: "80%" }}
                ></div>
              </div>
              <FaStar color={"ffc107"} size={25} />
              <FaStar color={"ffc107"} size={25} />
              <FaStar color={"ffc107"} size={25} />
              <FaStar color={"ffc107"} size={25} />
              <FaStar color={"e4e5e9"} size={25} />
              <span style={{ color: "#164C96" }} className="mt-3 sm:mt-0">
                80%
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 lg:px-32 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6">
        {reviews.map((review) => (
          <div key={review.id} className="p-6 rounded-lg">
            <div className="flex space-x-4">
              <div className="bg-[#164C96] rounded-full w-10 h-10 text-center flex items-center justify-center text-white">
                {review.user_id}
              </div>
              <div className="flex flex-col">
                <p className="text-l">Shubham Rajput</p>
                <p>
                  {review.comment} - {`${review.rating} Star`}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-10 mx-5 lg:mx-10 md:mx-10">
        <h2 className="text-center font-bold" style={{ fontSize: "32px" }}>
          Write a Review
        </h2>
        <div
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="justify-center mt-2" style={{ display: "flex" }}>
            <Review onRatingChange={handleRatingChange} />
          </span>
          <div className="flex justify-center font-semibold mt-1">
            Rate Product
          </div>
        </div>
        <div className="lg:ml-24 lg:mr-24 mt-10">
          <div className="mt-10">
            <h2 className="font-bold" style={{ color: "#1D2939" }}>
              Review Content
            </h2>

            <div className="  ">
              <textarea
                value={newReview.comment}
                onChange={handleCommentChange}
                className="w-full sm:w-full md:w-full lg:w-full border border-gray-300 mt-5 h-48 rounded-xl p-1"
              />{" "}
            </div>
          </div>

          <div className="mt-5 flex justify-center ">
            <button
              className="bg-[#952F8A] text-white px-4 py-2 rounded-3xl"
              onClick={submitReview}
            >
              Submit Review
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
